<template>
    <div class="login-container">
        <div class="to-register" @click="toRegister">註冊</div>
        <div class="appName">台醫通</div>
        <div>
            <div class="tip">賬號密碼登入</div>
            <div class="inp-container">
                <div class="wrapper wrapper-1">
                    <input v-model.trim="loginInfo.phoneNum" placeholder="手機號" class="inp inp-user" type="text">
                </div>
                <div class="password-container">
                    <div  class="wrapper wrapper-2">
                        <input :type="isClose ? 'password' : 'text'" v-model.trim="loginInfo.password" placeholder="密碼" class="inp inp-password">
                    </div>
                    <span @click="toggleEye" class="eye" :class="isClose ? 'close-eye' : ''"></span>
                </div>
                <span class="tip tip-forget" @click="forgetPassword">忘記密碼</span>
            </div>
        </div>
        <div class="login" @click="login">登入</div>
        <!-- <span @click="toggleLoginStyle">切换登入方式</span> -->
        <div class="read">
            <!-- <div class="choose-wrapper"><span class="choose" :class="isChecked ? 'active' : ''"></span></div> -->
            <span class="choose active"></span>
            <span class="text">我已仔細閱讀並接受<a class="protocol" @click.stop="watchProtocol">《台醫通服務平台用戶協議》</a></span>
        </div>
        <!-- 其他登入方式 -->
        <div class="other">
            <span class="other-title line">其他登入方式</span>
            <div class="other-desc" @click="toggleLoginStyle">
                <div class="other-desc-img">
                    <img src="../../assets/images/login_by_code.png" alt="">
                </div>
                <span class="other-title">短信登入</span>
            </div>
        </div>
    </div>
</template>
<script>
import {validateTel, validatePassword} from '@/utils/tools'
export default {
    data() {
        return {
            isClose: false,
            loginInfo: {
                phoneNum: '',
                password: ''
            },
            copyPassword: ''
        }
    },
    mounted() {
        // window.addEventListener("KeyboardDown",function() {
        //     scrollToPre()
        // },false);
    },
    methods: {
        forgetPassword() {
            // 忘记密码
            this.$router.push(`/forgetPassword?phone=${this.loginInfo.phoneNum}`)
        },
        toggleLoginStyle() {
            // 切换登入方式
            this.$router.push('/loginByCode')
        },
        login() {
            if(!validateTel(this.loginInfo.phoneNum) || !validatePassword(this.loginInfo.password)) {
                this.$toast({
                    message: '请按照正确格式输入手机号和密码',
                    position: 'top'
                })
                return
            }
            // 对手机号和输入密码进行校验
            let params = {
                telephone: this.loginInfo.phoneNum,
                password: this.$getRsaCode(this.loginInfo.password)
            }
            // 登入
            this.$store.dispatch('login', params).then(res => {
                // 登入成功跳转到首页
                // 登入成功存储token
                if(res.code == 200 && res.data.status == 1) {
                    localStorage.setItem('user_token', res.data.token)
                    // 登入将实名信息存储到layout
                    this.$store.commit('modifyRealNameInfo', res.data.user)
                    localStorage.setItem('phone', this.loginInfo.phoneNum)
                    this.$router.push('/')
                }else if(res.code == 200 && res.data.status == 20){
                    this.$toast({
                        message: '账号/密码出错'
                    })
                }else {
                    this.$toast({
                        message: res.msg
                    })
                }
            })
        },
        setHidePassword() {
            this.copyPassword = this.loginInfo.password;
            let hidePassword = ''
            for(let i =0; i < this.loginInfo.password.length; i++) {
                hidePassword += '*'
            }
            this.loginInfo.password = hidePassword
        },
        toggleEye() {
            this.isClose = !this.isClose
        },

        toRegister() {
            // 跳转到注册界面
            this.$router.push('/register')
        },

        watchProtocol() {
            // 查看协议
            console.log('1')
            this.$router.push('/read')
        }
    }
}
</script>
<style lang="scss" scoped>
    .login-container {
        width: 100%;
        min-height: 100vh;
        padding: 90px 40px 0 35px;
        box-sizing: border-box;
        background: #fff;
        .to-register {
            color: #00C2C1;
            font-size: 16px;
            position: absolute;
            top: 22px;
            right: 22px;
        }
        .appName {
            font-size: 28px;
            color: #02BCBD;
            font-family: PingFangSC-bold;
            margin-bottom: 60px;
        }

        .tip {
            font-size: 18px;
            color: #ccc;
            font-family: PingFangSC-regular;
            text-align: left;
            height: 40px;

            &-forget {
                color: #999;
                font-size: 14px;
                text-align: right;
                margin-top: 11px;
            }
        }

        .inp-container {
            display: flex;
            flex-direction: column;

            .wrapper {
                display: flex;
                align-items: center;
                position: relative;
                &-1::before {
                    content: '';
                    display: block;
                    width: 20px;
                    height: 20px;
                    background: url('../../assets/images/login-user.png');
                    background-size: 100%;
                    position: absolute;
                }

                &-2::before {
                    content: '';
                    display: block;
                    width: 24px;
                    height: 24px;
                    background: url('../../assets/images/login-lock.png');
                    background-size: 100%;
                    position: absolute;
                }

                .inp {
                    width: 300px;
                    height: 44px;
                    border-bottom: 1px solid #eee;
                    padding-left: 32px;
                    box-sizing: border-box;
                    font-size: 18px;
                    color: #101010;
                }
            }
            .password-container {
                position: relative;
                .eye {
                    display: block;
                    width: 24px;
                    height: 24px;
                    background: url('../../assets/images/visibility.png');
                    background-size: 100%;
                    position: absolute;
                    right: 0;
                    top: 10px;
                    &.close-eye {
                        background: url('../../assets/images/unVisibility.png');
                        background-size: 100%;
                    }
                }
            }
        }

        .login {
            width: 210px;
            height: 40px;
            line-height: 40px;
            font-size: 20px;
            color: #fff;
            background: #02BCBD;
            margin: 20px auto 0;
            border-radius: 20px;
        }

        .read {
            display: flex;
            width: 210px;
            margin: 0 auto;
            margin-top: 17px;
            margin-bottom: 55px;
            .choose {
                width: 24px;
                height: 24px;
                border: 1px solid #ccc;
                display: block;
                border-radius: 50%;
                margin-right: 2px;
                margin-top: 17px;

                &.active {
                    background: url('../../assets/images/checked.png') center center no-repeat;
                    background-size: 100%;
                    border: none;
                }
            }

            .text {
                text-align: left;
                margin-top: 17px;
                .protocol {
                    color: #02BCBD;
                }
            }
        }

        .choose-wrapper {
            width: 24px;
        }

    }

    .other {
        &-title {
            color: #999;
            font-size: 14px;
            margin: 7px;

            &.line {
                position: relative;
                &::before {
                    content: '';
                    display: block;
                    height: 1px;
                    width: 43px;
                    background: #eee;
                    position: absolute;
                    left: -40px;
                    top: 8px;
                }

                &::after {
                    content: '';
                    display: block;
                    height: 1px;
                    width: 43px;
                    background: #eee;
                    position: absolute;
                    right: -40px;
                    top: 8px;
                }
            }
        }

        &-desc {
            display: flex;
            flex-direction: column;
            align-items: center;
            &-img {
                width: 40px;
                height: 40px;
                background: #F2F2F2;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 7px;

                img {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
</style>
